<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_321_1065)">
      <path
        d="M9.5 0C7.71997 0 5.97991 0.527841 4.49987 1.51677C3.01983 2.50571 1.86628 3.91131 1.18509 5.55585C0.5039 7.20038 0.32567 9.00998 0.672937 10.7558C1.0202 12.5016 1.87737 14.1053 3.13604 15.364C4.39472 16.6226 5.99836 17.4798 7.74419 17.8271C9.49002 18.1743 11.2996 17.9961 12.9442 17.3149C14.5887 16.6337 15.9943 15.4802 16.9832 14.0001C17.9722 12.5201 18.5 10.78 18.5 9C18.5 6.61305 17.5518 4.32387 15.864 2.63604C14.1761 0.948212 11.887 0 9.5 0ZM9.6875 3.75C9.91001 3.75 10.1275 3.81598 10.3125 3.9396C10.4975 4.06321 10.6417 4.23891 10.7269 4.44448C10.812 4.65005 10.8343 4.87625 10.7909 5.09448C10.7475 5.31271 10.6403 5.51316 10.483 5.6705C10.3257 5.82783 10.1252 5.93498 9.90698 5.97838C9.68875 6.02179 9.46255 5.99951 9.25699 5.91436C9.05142 5.82922 8.87572 5.68502 8.7521 5.50002C8.62848 5.31501 8.5625 5.0975 8.5625 4.875C8.5625 4.57663 8.68103 4.29048 8.89201 4.0795C9.10299 3.86853 9.38914 3.75 9.6875 3.75ZM11.375 13.875H8.375C8.17609 13.875 7.98533 13.796 7.84468 13.6553C7.70402 13.5147 7.625 13.3239 7.625 13.125C7.625 12.9261 7.70402 12.7353 7.84468 12.5947C7.98533 12.454 8.17609 12.375 8.375 12.375H8.9375C8.98723 12.375 9.03492 12.3552 9.07009 12.3201C9.10525 12.2849 9.125 12.2372 9.125 12.1875V8.8125C9.125 8.76277 9.10525 8.71508 9.07009 8.67992C9.03492 8.64476 8.98723 8.625 8.9375 8.625H8.375C8.17609 8.625 7.98533 8.54598 7.84468 8.40533C7.70402 8.26468 7.625 8.07391 7.625 7.875C7.625 7.67609 7.70402 7.48532 7.84468 7.34467C7.98533 7.20402 8.17609 7.125 8.375 7.125H9.125C9.52283 7.125 9.90436 7.28304 10.1857 7.56434C10.467 7.84565 10.625 8.22718 10.625 8.625V12.1875C10.625 12.2372 10.6448 12.2849 10.6799 12.3201C10.7151 12.3552 10.7628 12.375 10.8125 12.375H11.375C11.5739 12.375 11.7647 12.454 11.9053 12.5947C12.046 12.7353 12.125 12.9261 12.125 13.125C12.125 13.3239 12.046 13.5147 11.9053 13.6553C11.7647 13.796 11.5739 13.875 11.375 13.875Z"
        fill="#0EAA69"
      />
    </g>
    <defs>
      <clipPath id="clip0_321_1065">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import Vue from "vue";
export default Vue.extend ({
  name: 'InfoIcon'
})
</script>


















































import DataTable from '@/components/DataTable/DataTable.vue'
import Table from '@/components/Table/Table.vue'
import tableMixins from '@/mixins/TableMixins/tableMixins.js'
import endpoints from '@/utils/endpoints.js'
import moment from 'moment'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    Table,
    DataTable
  }
})
export default class MessageTemplateList extends mixins(tableMixins) {
  currentPage = 1
  handlePageChange!: any
  handlePageSizeChange!: any
  isBusy = true
  messageTemplates = []
  pageSize = 100
  systemTemplates = [
    'AUTOPAY',
    'AUTOPAY_DISABLED',
    'AUTOPAY_DISABLED_MERCHANT',
    'AUTOPAY_MERCHANT',
    'PAYMENT_FAILED',
    'PAYMENT_PROMISE',
    'PAYMENT_PROMISE_CONFIRMED',
    'PAYMENT_SCHEDULED',
    'PAYMENTREQUEST',
    'THANKS',
    'WELCOME',
    'UNCONFIRMED_PROMISE',
    'UNCONFIRMED_PAYMENT_METHOD'
  ]

  actions = [
    {
      label: 'Create Template',
      outline: false,
      action: this.createTemplate,
      requireSelection: false
    }
  ]
  tableData = {
    headers: [
      {
        key: 'name',
        label: 'Name'
      },
      {
        key: 'active',
        label: 'Active'
      },
      {
        label: 'Delete',
        key: 'delete'
      }
    ],
    items: this.messageTemplates
  }

  totalMessageTemplates = 0
  totalPages: null | number = null

  mounted() {
    const navTitle = [{ crumbTitle: 'Templates' }]

    this.$store.dispatch('setPageTitle', navTitle)
    this.getMessageTemplates()
  }

  createTemplate() {
    this.$router.push({
      name: 'MessageTemplateEdit',
      params: { merchantID: this.$route.params.merchantID }
    })
  }

  async getMessageTemplates() {
    try {
      const response = await this.axios.get(
        endpoints.listMessageTemplates(this.$route.params.merchantID),
        {
          params: { page: this.currentPage, page_size: this.pageSize }
        }
      )
      this.messageTemplates = response.data.results
      this.tableData.items = this.messageTemplates
      this.totalMessageTemplates = response.data.count
    } catch (err) {
      console.log('error', err)
    } finally {
      this.isBusy = false
      this.totalPages = Math.ceil(this.totalMessageTemplates / this.pageSize)
    }
  }

  handlePage(newPage) {
    this.handlePageChange(newPage)
    this.getMessageTemplates()
  }

  handlePageSize(pageSize) {
    this.handlePageSizeChange(pageSize)
    this.getMessageTemplates()
  }

  handleActiveToggle(data) {
    try {
      this.axios.put(
        endpoints.updateMessageTemplate(
          this.$route.params.merchantID,
          data.item.id
        ),
        {
          ...data.item,
          active: !data.item.active
        }
      )
    } catch (err) {
      console.log('error', err)
    }
  }

  async handleDelete(data) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you want to delete this template?', {
        title: 'Confirm',
        okVariant: 'danger'
      })
      .then(value => {
        if (value) {
          try {
            this.axios
              .delete(
                endpoints.updateMessageTemplate(
                  this.$route.params.merchantID,
                  data.item.id
                ),
                {
                  ...data.item,
                  //deleted uses moment to get the date in the following format: YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]."]
                  deleted: moment().format(),
                  active: false
                }
              )
              .then(() => {
                this.getMessageTemplates()
              })
          } catch (err) {
            console.log('error', err)
          }
        }
      })
  }

  handleRowClicked(data) {
    console.log('data', data.id)
    this.$router.push({
      name: 'MessageTemplateEdit',
      params: {
        merchantID: this.$route.params.merchantID,
        messageTemplateID: data.id
      }
    })
  }
  get hasItems() {
    return this.messageTemplates.length >= 1
  }
}

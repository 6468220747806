var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blytz-datatable"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"actions"},_vm._l((_vm.actions),function(action){return _c('b-button',{key:action.label,attrs:{"variant":action.outline ? 'outline-success' : 'success',"disabled":action.requireSelection && _vm.selected.length === 0},on:{"click":function () { return action.action(_vm.selected); }}},[_vm._v(" "+_vm._s(action.label)+" ")])}),1)]),_c('div',{staticClass:"filters"},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.label},[(filter.type === 'date')?_c('BlytzDatePicker',{attrs:{"start-date":filter.props.startDate.toString(),"end-date":filter.props.endDate.toString(),"onDateChange":filter.onFilter}}):(filter.type === 'toggle')?_c('SwitchInput',{attrs:{"label":filter.label},on:{"change":filter.onFilter}}):_vm._e()],1)}),0),_c('b-table',{class:_vm.className,attrs:{"fields":_vm.fields,"items":_vm.tableData.items,"striped":"","selectable":_vm.selectable,"th-class":"table-header-column"},on:{"sort-changed":_vm.onSortChanged,"row-selected":_vm.onRowSelected,"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"head(checkbox)",fn:function(ref){
var selectAllRows = ref.selectAllRows;
var clearSelected = ref.clearSelected;
return [_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.allRowsSelected},on:{"click":function () { return (_vm.allRowsSelected ? clearSelected() : selectAllRows()); }}})]}},{key:"cell(checkbox)",fn:function(ref){
var rowSelected = ref.rowSelected;
var selectRow = ref.selectRow;
var unselectRow = ref.unselectRow;
return [_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":rowSelected},on:{"click":function($event){rowSelected ? unselectRow() : selectRow()}}})]}},(_vm.onShowDetails)?{key:"cell(details)",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"info-button",on:{"click":function () { return _vm.onShowDetails(item); }}},[_c('InfoIcon')],1)]}}:null,_vm._l((_vm.$scopedSlots),function(_,slotName){return {key:slotName,fn:function(scope){return [_vm._t(slotName,null,null,scope)]}}})],null,true)}),(_vm.paginated)?_c('DataTablePagination',{attrs:{"onPageSizeChange":_vm.onPageSizeChange,"onPageChange":_vm.onPageChange,"totalRecords":_vm.totalRecords,"pageSize":_vm.pageSize,"currentPage":_vm.currentPage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
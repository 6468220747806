<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M9.06712 23.8333L7.63379 22.4L14.0338 16L7.63379 9.6L9.06712 8.16667L16.9005 16L9.06712 23.8333ZM22.0005 24V8H24.0005V24H22.0005Z"
      :fill="color"
      fill-opacity="0.38"
    />
  </svg>
</template>

<script>
export default {
  name: 'NavLastIcon',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: Number,
      default: 32
    }
  }
}
</script>

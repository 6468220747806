















import Vue from 'vue'

export default Vue.extend({
  name: 'SwitchInput',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      default: event => null
    }
  }
})

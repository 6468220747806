<template>
  <div class="v-flex table-container">
    <div class="table-content-container">
      <b-table
        v-bind="$attrs"
        selectable
        select-mode="single"
        hover
        @sort-changed="e => $emit('sortChanged', e)"
        @row-clicked="handleClick"
      >
        <slot />
        <slot slot="thead-top" name="thead-top" />

        <template
          v-if="$attrs.busy && !($attrs.items && $attrs.items.length)"
          #table-busy
        >
          <div class="text-center my-2 table-loading">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #head(checkbox)="$attrs">
          <b-checkbox
            v-if="$attrs.field.checkbox"
            :checked="isAllSelected"
            :indeterminate="isIndeterminate"
            class="custom-checkbox"
            @change="selectAll"
          />
        </template>
        <template #head(unread_messages)="$attrs">
          <fa-icon
            v-if="$attrs.field.icon"
            :icon="$attrs.field.icon.iconName"
            :title="$attrs.field.icon.title"
          />
        </template>

        <template #cell()="$attrs">
          <div>
            <router-link
              v-if="$attrs.field.link === true"
              :to="$attrs.item.id"
              append
              :data-cy="makeSelector($attrs)"
            >
              {{ $attrs.item[$attrs.field.key] }}
            </router-link>

            <div v-else-if="$attrs.field.isStatus" class="status-cell">
              <fa-icon
                :class="getStatusClass($attrs.item[$attrs.field.key])"
                :icon="['fas', 'circle']"
              />
              {{ $attrs.item[$attrs.field.key] }}
            </div>

            <div v-else-if="$attrs.field.checkbox">
              <b-form-checkbox
                v-if="checkboxIgnore($attrs)"
                v-model="$attrs.item[$attrs.field.key]"
                class="custom-checkbox"
                @change="
                  $emit(
                    'checkboxChanged',
                    $attrs.item,
                    $attrs.item[$attrs.field.key]
                  )
                "
              />
              <b-button
                v-if="!checkboxIgnore($attrs)"
                v-b-tooltip.hover.topright
                title="Auto generated templates must remain active"
                class="info-icon-button-restyling"
              >
                <fa-icon icon="info" />
              </b-button>
            </div>

            <div v-else-if="$attrs.field.template">
              template
            </div>

            <div v-else-if="$attrs.field.button">
              <b-button
                v-model="$attrs.item[$attrs.field.key]"
                class="custom-button"
                @click="$emit('buttonClicked', $attrs.item)"
              >
                {{ $attrs.field.button.text }}
              </b-button>
            </div>

            <div v-else-if="$attrs.field.info">
              {{ formatOutput($attrs) }}
              <span :id="`info-${$attrs.index}`">
                <b-button class="info-icon-button-restyling" disabled>
                  <fa-icon icon="info" class="cursor-default" />
                </b-button>
              </span>
              <b-tooltip :target="`info-${$attrs.index}`" placement="left">
                <div
                  v-for="(item, index) in $attrs.field.info"
                  :key="item.label"
                >
                  {{ item.label }}: {{ formatOutput($attrs, index) }}
                </div>
              </b-tooltip>
            </div>

            <div v-else>
              {{ formatOutput($attrs) }}
            </div>

            <small v-if="details($attrs)">
              {{ details($attrs) }}
            </small>
          </div>
        </template>

        <template v-if="rowActions" #cell(actions)="data">
          <div class="action-cell-inner">
            <div class="table-actions">
              <span
                v-for="action of rowActions"
                :key="action.name"
                :data-cy="action.name.toLowerCase()"
                :disabled="action.disable && action.disable(data.item)"
              >
                <fa-icon
                  v-if="action.visible ? action.visible(data.item) : true"
                  :icon="action.icon"
                  :title="action.iconTitle"
                  @click.stop="$emit(action.name, data.item)"
                />
              </span>
            </div>
          </div>
        </template>

        <template #row-details="row">
          <slot
            v-if="expandable && customExpandableContent"
            name="expandable"
            v-bind="row"
          />
          <small v-else-if="expandable && expandableMessage">
            {{ expandableContent() }}
          </small>
        </template>
      </b-table>
    </div>

    <TableFooter
      v-if="showFooter"
      :page-size-options="pageSizeOptions"
      :current-page-size="currentPageSize"
      :total-pages="totalPages"
      :current-page="currentPage"
      @pageChanged="handlePageChange"
      @pageSizeChanged="handlePageSizeChange"
    />
  </div>
</template>

<script>
import TableFooter from '@/components/Table/TableFooter.vue'
import {
  phoneFormatter,
  moneyFormatter,
  standardFormatter,
  dateTimeLocalFormatter,
  promiseFormatter,
  basisPointsFormatter
} from '@/utils/formatters.ts'

export default {
  components: {
    TableFooter
  },
  props: {
    pageSize: {
      default: 50,
      type: Number
    },
    currentPage: {
      default: 1,
      type: Number
    },
    totalPages: {
      default: null,
      type: Number
    },
    hasFooter: {
      default: true,
      type: Boolean
    },
    clickable: {
      default: () => {
        return true
      },
      type: [Function, Boolean]
    },
    customSelect: {
      default: false,
      type: Boolean
    },
    rowActions: {
      default: null,
      type: Array
    },
    expandable: {
      default: false,
      type: Boolean
    },
    customExpandableContent: {
      default: false,
      type: Boolean
    },
    expandableMessage: {
      default: () => '',
      type: [Function, String]
    },
    isAllSelected: {
      default: false,
      type: Boolean
    },
    isIndeterminate: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      pageSizeOptions: [50, 75, 100],
      currentPageSize: 50
    }
  },

  computed: {
    showFooter() {
      return this.hasFooter && this.$attrs?.items?.length >= 1
    }
  },

  mounted() {
    this.currentPageSize = this.pageSize
  },

  methods: {
    checkboxIgnore(attrs) {
      return !attrs?.field?.checkbox?.ignore?.includes(
        attrs.item[attrs?.field?.checkbox?.ignoreBy]
      )
    },

    details(attrs) {
      let result = ''
      if (attrs?.item?.details && attrs?.item?.details[attrs?.field?.key])
        result = attrs?.item?.details[attrs?.field?.key]

      return result
    },

    expandableContent() {
      return this.expandable
        ? this.$attrs.items.find(element => element._showDetails)?.payment[
            this.expandable
          ]
        : ''
    },

    getStatusClass(status) {
      const success = ['active']

      const warning = ['pending']

      const muted = ['closed', 'inactive']

      if (success.includes(status.toLowerCase())) return 'text-success'
      if (warning.includes(status.toLowerCase())) return 'text-warning'
      if (muted.includes(status.toLowerCase())) return 'text-muted'
    },

    handleClick(item) {
      if (this.expandable && this.clickable(item?.status)) {
        this.$attrs.items.map(ele => {
          if (ele.id === item.id && !ele._showDetails) {
            this.$set(ele, '_showDetails', true)
          } else {
            this.$set(ele, '_showDetails', false)
          }
        })
      } else if (!this.expandable && this.clickable) {
        if (this.customSelect) {
          this.$emit('handleClick', item)
        } else {
          const next = this.$route.path + '/' + item.id
          this.$router.push(next)
        }
      }
    },

    routeByName(attrs) {
      const routeTo = {
        name: attrs.field.link.name,
        params: { merchantID: this.$store.getters.profile.organization.id }
      }
      routeTo.params[attrs.field.link.paramID] = attrs.item.customerId
      return routeTo
    },

    formatOutput(attrs, index = 0) {
      let tableItem =
        attrs.item[
          attrs?.field?.info ? attrs?.field?.info[index].key : attrs.field.key
        ]

      if (attrs?.field?.parent) {
        const parent =
          attrs.item[attrs.field.parent][0] || attrs.item[attrs.field.parent]
        if (parent[attrs.field.key]) tableItem = parent[attrs.field.key]
      }

      switch (attrs?.type ?? attrs.field.type) {
        case 'phone':
          return phoneFormatter(tableItem)
        case 'amount':
          return moneyFormatter(tableItem)
        case 'datetime-local':
          return dateTimeLocalFormatter(tableItem)
        case 'promise':
          return promiseFormatter(tableItem)
        case 'basis-points':
          return basisPointsFormatter(tableItem)
        default:
          return standardFormatter(tableItem)
      }
    },

    handlePageChange(newPage) {
      this.$emit('pageChanged', newPage)
    },

    handlePageSizeChange(newSize) {
      this.$emit('pageSizeChanged', newSize)
    },

    makeSelector(attrs) {
      return attrs?.cy ?? attrs?.value?.toLowerCase() ?? ''
    },
    selectAll(event) {
      this.$emit('selectAll', event)
    }
  }
}
</script>

<style lang="scss">
table > tbody > tr > :nth-child(1n) :not(.table-loading) {
  max-width: 100%;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  .custom-checkbox label {
    overflow: visible;
  }
}

.custom-checkbox {
  .custom-control-input:checked,
  .custom-control-input:indeterminate {
    ~ .custom-control-label::before {
      background-color: $table-header-color !important;
      border: 1px solid $table-header-color;
      border-radius: 2px;
    }
  }

  .custom-control-label::after {
    padding-left: 7px;

    border: 1px solid $table-header-color;
    border-radius: 2px;
  }
}
</style>

<style lang="scss" scoped>
.table-container {
  flex: 1;
  overflow: hidden;
}

.table-content-container {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome */
  }
}

.custom-select {
  height: 25px;
  padding-top: 0;
  padding-bottom: 0;

  background-color: transparent;
}

.custom-control.custom-checkbox {
  padding-left: 2em;
}

.b-skeleton {
  width: 50px;
}

.action-cell-inner {
  display: flex;
  justify-content: flex-end;
}

.table-actions {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: max-content;
  [disabled='disabled'] {
    opacity: 0.3;

    pointer-events: none;
  }
  span {
    padding: 0 0.1rem;

    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}

.status-cell {
  display: flex;
  align-items: center;

  svg {
    height: 0.5rem;
  }
}
</style>

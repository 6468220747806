<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M12.4997 24L11.0664 22.5667L17.6664 15.9667L11.0664 9.36668L12.4997 7.93335L20.5331 15.9667L12.4997 24Z"
      :fill="color"
      fill-opacity="0.38"
    />
  </svg>
</template>

<script>
export default {
  name: 'NavNextIcon',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: Number,
      default: 32
    }
  }
}
</script>

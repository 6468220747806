import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('full_name', {
  ...required,
  validate: value => {
    const pattern = /\b\w+\b(?:\s+\b\w+\b)+/
    return pattern.test(value)
  },
  message: 'This field must be a full name'
})

import Vue from 'vue'
Vue.config.productionTip = false
import './utils/custom-validations'

import App from './App.vue'
import router from '@/router/index'
import store from '@/store/index'

import axios from '@/utils/init-axios'

import endpoints from '@/plugins/endpoints'
Vue.use(endpoints)

import eventbus from '@/plugins/eventbus'
Vue.use(eventbus)

import { States } from '@/plugins/states'
Vue.use(States)

import { title } from '@/utils/filters'
Vue.filter('title', title)

// Bootstrap-Vue
import {
  // Plugins
  BadgePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  ButtonToolbarPlugin,
  CardPlugin,
  CollapsePlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  SkeletonPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBModalPlugin,
  VBTooltipPlugin,
  PopoverPlugin,
  ProgressPlugin,
  OverlayPlugin,
  // Icons
  BIcon,
  BIconBackspaceFill,
  BIconBoxArrowRight,
  BIconCaretLeftFill,
  BIconCaretRightFill,
  BIconCashStack,
  BIconChatRightDotsFill,
  BIconEnvelopeFill,
  BIconFileBarGraphFill,
  BIconGrid1x2Fill,
  BIconInbox,
  BIconList,
  BIconPencilFill,
  BIconPersonCircle,
  BIconPeopleFill,
  BIconPersonFill,
  BIconSearch,
  AlertPlugin,
  BProgress
} from 'bootstrap-vue'

Vue.use(BadgePlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(ButtonToolbarPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(SkeletonPlugin)
Vue.use(SidebarPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)
Vue.use(VBModalPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(PopoverPlugin)
Vue.use(AlertPlugin)
Vue.use(ProgressPlugin)
Vue.use(OverlayPlugin)

Vue.component('BIcon', BIcon)
Vue.component('BIconBackspaceFill', BIconBackspaceFill)
Vue.component('BIconBoxArrowRight', BIconBoxArrowRight)
Vue.component('BIconCaretLeftFill', BIconCaretLeftFill)
Vue.component('BIconCaretRightFill', BIconCaretRightFill)
Vue.component('BIconCashStack', BIconCashStack)
Vue.component('BIconChatRightDotsFill', BIconChatRightDotsFill)
Vue.component('BIconFileBarGraphFill', BIconFileBarGraphFill)
Vue.component('BIconEnvelopeFill', BIconEnvelopeFill)
Vue.component('BIconGrid1x2Fill', BIconGrid1x2Fill)
Vue.component('BIconInbox', BIconInbox)
Vue.component('BIconList', BIconList)
Vue.component('BIconPencilFill', BIconPencilFill)
Vue.component('BIconPeopleFill', BIconPeopleFill)
Vue.component('BIconPersonCircle', BIconPersonCircle)
Vue.component('BIconPersonFill', BIconPersonFill)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BProgress', BProgress)

import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

import VueTagsInput from '@johmun/vue-tags-input'
Vue.use(VueTagsInput)

/* styles */
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/scss/main.scss'
import '@/scss/segments.lib.css'
import '@/utils/common-validators'

import { LocalError } from '@/utils/exceptions'
import { i18n } from '@/locales/i18n-setup'

/* FontAwesome Config */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import iconLibrary from '@/utils/icon-library'
library.add(...iconLibrary)
Vue.component('FaIcon', FontAwesomeIcon)

import * as Sentry from '@sentry/vue'

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  router
})

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'USD' }
})

import Hotjar from 'vue-hotjar'

Vue.use(Hotjar, {
  id: '5193054',
  isProduction: window.VUE_APP_ENV === 'blytzpay-prod'
})

import { ValidationObserver, ValidationProvider } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/* Set the access for quickest possible auth access */
const accessToken = localStorage.getItem('blytzpay_access_token')
axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

import VueGtag from 'vue-gtag'
if (window && window.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: window.VUE_APP_GOOGLE_ANALYTICS_ID }
    },
    router
  )
}

Vue.toasted.register(
  'error',
  payload => {
    return payload.message ? payload.message : 'Oops! Something Went Wrong...'
  },
  {
    type: 'error',
    position: 'bottom-center',
    duration: 10000,
    action: {
      text: 'Dismiss',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  }
)

// Toast unhandled exceptions in non-prod environments in addition to putting 'em in the console.
if (window.VUE_APP_ENV !== 'blytzpay-prod') {
  Vue.config.errorHandler = (error: any, vue) => {
    const message =
      error?.response?.data?.detail ??
      error?.response?.data?.non_field_errors ??
      error.message

    // eslint-disable-next-line no-console
    console.error(error)
    vue.$toasted.global.error({ message: message })
    return false
  }

  window.addEventListener('unhandledrejection', event => {
    // Don't propagate these. They're mostly noise.
    if (event.reason?.name === 'NavigationDuplicated') {
      event.preventDefault()
      return true
    }

    const message =
      event.reason?.response?.data?.detail ??
      event.reason?.response?.data?.non_field_errors ??
      event.reason

    if (!event.reason.isSilent) {
      if (event.reason?.response?.status === 401) {
        throw new LocalError('Unauthenticated')
      } else {
        // eslint-disable-next-line no-console
        console.error(event, message)
        Vue.toasted.global.error({ message: message })
      }
    }
    return false
  })
}

if (window.VUE_APP_ENV !== 'localhost') {
  Sentry.init({
    Vue: Vue,
    dsn: window.VUE_APP_SENTRY_UI,
    ignoreErrors: ['LocalError', 'NavigationDuplicated']
  })
}

const app = new Vue({
  router,
  store,
  i18n,
  async beforeCreate() {
    /* Also refresh the access token on initial load */
    const refreshToken = localStorage.getItem('blytzpay_refresh_token')
    if (refreshToken) {
      try {
        const response = await this.$store.dispatch('refreshAccess')
        const data = response?.data

        if (data) {
          this.axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${data.access}`

          await Promise.all([
            this.$store.dispatch('getProfile'),
            this.$store.dispatch('getMerchant', {
              merchantID: this.$route.params?.merchantID
            }),
            this.$store.dispatch('getMerchantCount')
          ])

          this.$store.commit('setRefreshComplete', true)
        }
      } catch {
        // TODO: Maybe go to a "oh no you broke it" page?
      }
    }
  },
  render: h => h(App)
}).$mount('#app')

declare global {
  interface Window {
    Cypress: unknown
    app: unknown
  }
}
if (window.Cypress) {
  window.app = app
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M8 24V8H10V24H8ZM22.9 23.9L15.1 16.1L22.9 8.3L24.3333 9.73333L17.9667 16.1L24.3333 22.4667L22.9 23.9Z"
      :fill="color"
      fill-opacity="0.38"
    />
  </svg>
</template>

<script>
export default {
  name: 'NavFirstIcon',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: Number,
      default: 32
    }
  }
}
</script>

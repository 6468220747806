































































































































































































import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue'
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue'
import { DiscardDraftConfirmation } from '@/mixins/ModalMixins/ModalMixins'
import endpoints from '@/utils/endpoints.js'
import { ValidationObserver } from 'vee-validate'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'

Component.registerHooks(['beforeRouteLeave'])

interface MessageTemplate {
  delta_days: null | number
  email_body: string
  name: string
  sms_body: string
  type: string
}

@Component({
  components: {
    SelectInput,
    TextInput
  }
})
export default class UpdateMessageTemplate extends mixins(
  DiscardDraftConfirmation
) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }

  dayInRelation: null | number = null
  messageTemplate: MessageTemplate = {
    delta_days: null,
    email_body: '',
    name: '',
    sms_body: '',
    type: ''
  }
  notifyOptions = [
    { value: null, text: 'No automation ' },
    { value: 1, text: 'Before due date' },
    { value: 0, text: 'On due date' },
    { value: -1, text: 'After due date' }
  ]
  recurrence_date = ''
  responseErrors = null
  isBusy = false
  isDraft = false

  async beforeRouteLeave(to, from, next) {
    if (this.isDraft) {
      const response = await this.discardDraftConfirmation()
      if (!response) return
    }
    next()
  }

  mounted() {
    this.setNav()
    if (this.$route.params.messageTemplateID) this.getMessageTemplate()
  }

  displayName(type) {
    const names = {
      AUTOPAY: 'Autopay',
      AUTOPAY_DISABLED: 'Autopay Disabled',
      AUTOPAY_MERCHANT: 'Autopay By Merchant',
      AUTOPAY_DISABLED_MERCHANT: 'Autopay Disabled By Merchant',
      PAYMENT_FAILED: 'Payment Failed',
      PAYMENT_PROMISE: 'Confirm Payment',
      PAYMENT_PROMISE_CONFIRMED: 'Payment Arrangement Confirmation',
      PAYMENT_SCHEDULED: 'Payment Scheduled',
      PAYMENTREQUEST: 'Payment Request',
      THANKS: 'Thank You',
      WELCOME: 'Welcome',
      UNCONFIRMED_PROMISE: 'Unconfirmed Promise Reminder',
      UNCONFIRMED_PAYMENT_METHOD: 'Unconfirmed Payment Method'
    }

    return names[type]?.toUpperCase()
  }

  getMessageTemplate() {
    this.axios
      .get(
        endpoints.retrieveMessageTemplate(
          this.$route.params.merchantID,
          this.$route.params.messageTemplateID
        )
      )
      .then(res => {
        if (res.data.delta_days === 0) {
          this.dayInRelation = 0
        } else if (res.data.delta_days != null) {
          this.dayInRelation =
            Math.abs(res.data.delta_days) / res.data.delta_days
        } else {
          this.dayInRelation = res.data.delta_days
        }

        let english_text_body = res.data.message_bodies.filter(
          e => e.language === 'en-US' && e.body_type === 'TEXT'
        )
        delete res.data.message_bodies
        this.messageTemplate = res.data
        this.messageTemplate.sms_body = english_text_body[0]?.message_body
        this.messageTemplate.delta_days =
          typeof this.messageTemplate.delta_days === 'number'
            ? Math.abs(this.messageTemplate.delta_days)
            : null
      })
  }

  handleCancel() {
    this.$router.push({
      name: 'MessageTemplateList',
      params: { ...this.$route.params }
    })
  }

  async handleSubmit() {
    let response: any = {}
    this.isBusy = true
    const requestType = this.$route.params.messageTemplateID ? 'PUT' : 'POST'
    const payload = {
      name: this.messageTemplate.name,
      delta_days: this.messageTemplate.delta_days,
      message_bodies: [
        {
          language: 'en-US',
          body_type: 'TEXT',
          message_body: this.messageTemplate.sms_body
        },
        {
          language: 'en-US',
          body_type: 'EMAIL',
          message_body: this.messageTemplate.sms_body
        }
      ]
    }

    if (payload['delta_days'] != null && this.dayInRelation != null) {
      payload['delta_days'] = payload['delta_days'] * this.dayInRelation
    }

    if (this.isSpecial(this.messageTemplate.type)) {
      payload['type'] = this.messageTemplate.type
    } else if (this.dayInRelation === null) {
      payload['type'] = 'UNSCHEDULED'
    } else {
      payload['type'] = 'SCHEDULED'
    }

    this.isDraft = false
    try {
      response = await this.axios({
        method: requestType,
        url: this.messageTemplateSubmitTo,
        data: payload
      })

      this.$toasted.show('Template saved!', {
        duration: 2000,
        position: 'bottom-center'
      })

      if (!this.$route.params.messageTemplateID) {
        this.$router.push({
          name: 'MessageTemplateEdit',
          params: { messageTemplateID: response.data.id }
        })
      }
    } catch (error) {
      this.$refs.observer.setErrors(error.response.data)
      this.isDraft = true
    } finally {
      this.isBusy = false
      this.setNav()
    }
  }

  isCurrentRoute(route) {
    return route === this.$route.name ? 'dark' : 'light'
  }

  isSpecial(type) {
    const types = [
      'AUTOPAY',
      'AUTOPAY_DISABLED',
      'AUTOPAY_MERCHANT',
      'AUTOPAY_DISABLED_MERCHANT',
      'PAYMENT_FAILED',
      'PAYMENT_PROMISE',
      'PAYMENT_PROMISE_CONFIRMED',
      'PAYMENT_SCHEDULED',
      'PAYMENTREQUEST',
      'THANKS',
      'WELCOME',
      'UNCONFIRMED_PROMISE',
      'UNCONFIRMED_PAYMENT_METHOD'
    ]

    return !!types.find(t => t === type)
  }

  setDraft() {
    this.isDraft = true
    this.$refs.observer.reset()
  }

  setNav() {
    let currentNavTitle = this.$route.params.messageTemplateID
      ? 'Edit Template'
      : 'Create Template'

    const navTitle = [
      { crumbTitle: 'Templates', directTo: 'MessageTemplateList' },
      { crumbTitle: currentNavTitle }
    ]

    this.$store.dispatch('setPageTitle', navTitle)
  }

  updateDays() {
    if (this.dayInRelation != 0 && this.messageTemplate.delta_days === 0) {
      this.messageTemplate.delta_days = 1
    } else if (this.dayInRelation === null) {
      this.messageTemplate.delta_days = null
    }
  }

  updateType() {
    if (this.messageTemplate.delta_days === 0) this.dayInRelation = 0
  }

  get isSaveDisabled() {
    return this.messageTemplate.sms_body?.length === 0
  }

  get messageTemplateSubmitTo() {
    return this.$route.params.messageTemplateID
      ? endpoints.updateMessageTemplate(
          this.$route.params.merchantID,
          this.$route.params.messageTemplateID
        )
      : endpoints.createMessageTemplate(this.$route.params.merchantID)
  }
}


















































import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { Vue } from 'vue-property-decorator'

interface Interval {
  label: string
  value: number
  default: boolean
}
export default Vue.extend({
  name: 'BlytzDatePicker',
  components: {
    DatePicker
  },
  props: {
    singleField: {
      type: Boolean,
      default: false
    },
    intervals: {
      type: Array as () => Interval[],
      default: () => []
    },
    columns: {
      type: Number,
      default: 2
    },
    separator: {
      type: String,
      default: 'to'
    },
    onDateChange: {
      type: Function,
      default: (date: { start: string; end: string }) => String
    },
    startDate: {
      type: String,
      default: new Date().toString()
    },
    endDate: {
      type: String,
      default: new Date().toString()
    }
  },
  data() {
    return {
      date: {
        start: new Date(this.startDate),
        end: new Date(this.endDate)
      },
      selectedInterval: `${this.intervals?.find((interval) => interval.default)?.value}`
    }
  },
  methods: {
    updateDate(newDate: { start: Date; end: Date }) {
      this.date = newDate
      this.onDateChange(this.date)
      this.$emit('input', this.date)

      //check if the selected date range is present in the intervals, if so select it, otherwise will select "custom"
      const interval = this.intervals.find(
        (interval) =>
          new Date().getDate() - new Date(newDate.start).getDate() ===
          interval.value
      )
      this.selectedInterval = (interval?.value) ? `${interval.value}` : "custom";
    },
    onChangeInterval(event) {
      const interval = this.intervals.find(
        (interval) => interval.value === Number(event.target.value)
      )

      if (interval) {
        const start = new Date()
        const end = new Date()
        start.setDate(start.getDate() - interval.value)
        this.date = { start, end }
        this.$emit('input', this.date)
      } else {
        event.target.value = "custom";
      }
    }
  }
})
